<template>
	<div class="items-table-container orders-table">
		<VueElementLoading
			class="section-block"
			:active="itemsLoading"
			spinner="line-scale"
			:text="`загрузка ${itemsName.mult} ...`"
			:background-color="'rgba(255, 255, 255, .7)'"
		/>

		<!-- v-show="tableData.length" -->
		<el-table
			ref="Table"
			:stripe="true"
			:data="tableData"
			default-expand-all
			@sort-change="handleSort"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="expand" width="1">
				<template slot-scope="scope">
					<div class="cell products-list">
						<div
							v-for="order_product in scope.row.orderProducts"
							:key="`order_product-${order_product.id}`"
							class="product-item"
						>
							<div class="code" v-text="order_product.product.code"></div>
							<div class="name" v-text="order_product.product.title_ru"></div>
							<div class="quantity" v-text="`${order_product.quantity} шт.`"></div>
							<div class="amount" v-text="`${order_product.amount} грн`"></div>
						</div>
					</div>
				</template>
			</el-table-column>
			<!-- :cell-class-name="" -->
			<el-table-column type="selection" width="45"></el-table-column>

			<template v-for="(column, columnIndex) in tableSettings.columns">
				<!-- v-if="column.meta && column.meta.path" -->
				<el-table-column
					:key="`tableColumn-${columnIndex}`"
					:property="getProperty(column)"
					:label="column.label"
					:width="column.width || ''"
					:min-width="column.min_width || ''"
					:show-overflow-tooltip="column.overflowTooltip"
					:sortable="column.sortable ? 'custom' : false"
				>
					<template slot-scope="scope">
						<div
							v-if="column.meta && column.meta.isButton"
							class="table-button"
							@click="emitEvent(column.meta.eventName, scope.row)"
							v-text="getCellValue(scope.row, column)"
						></div>

						<template v-else>
							<div
								:class="
									column.meta && column.meta.cellClass ? column.meta.cellClass : ''
								"
							>
								<div v-html="getCellValue(scope.row, column)"></div>
							</div>
						</template>
					</template>
				</el-table-column>
			</template>

			<!-- :width="column.width || ''" -->
			<!-- :min-width="column.min_width || ''" -->
			<el-table-column :property="'user'" label="Клиент">
				<template slot-scope="scope">
					<div class="user-cell">
						<div>{{scope.row.user.full_name}}</div>
						<div>{{scope.row.user.email}}</div>
						<div>{{scope.row.user.phone_number}}</div>
					</div>
				</template>
			</el-table-column>
			
			<el-table-column :property="'delivery_type'" label="Доставка">
				<template slot-scope="scope">
					<div class="delivery-cell" v-html="getDeliveryValue(scope.row)"></div>
				</template>
			</el-table-column>

			<el-table-column :property="'payment_type'" label="Оплата">
				<template slot-scope="scope">
					<div class="" v-text="getPaymentValue(scope.row)"></div>
				</template>
			</el-table-column>

			<el-table-column :property="'payment_type'" label="Сумма">
				<template slot-scope="scope">
					<div class="final_amount">
						<b>{{ scope.row.final_amount }} грн</b>
					</div>
				</template>
			</el-table-column>

			<el-table-column :property="'comment'" label="Комментарий">
				<template slot-scope="scope">
					<div class="" v-text="scope.row.comment || '-'"></div>
				</template>
			</el-table-column>

			<el-table-column :property="'status'" label="Статус">
				<template slot-scope="scope">
					<div class="input-item-block">
						<el-select
							:value="scope.row.order_status_id"
							placeholder="выберите статус"
							:disabled="!orderStatusesList.length"
							@change="
								statusId =>
									handleStatusChange({
										statusId: statusId,
										item: scope.row,
										columnIndex: scope.$index
									})
							"
						>
							<el-option
								v-for="item in orderStatusesList"
								:key="'status-' + item.id"
								:label="item.title"
								:value="item.id"
							/>
						</el-select>
					</div>
				</template>
			</el-table-column>

			<el-table-column
				v-if="tableSettings.operations"
				align="right"
				:width="getWidth(tableSettings.operations)"
				class="operations-column"
				label="Действия"
			>
				<template slot-scope="scope">
					<div v-show="activeRowIdx !== scope.$index">
						<el-button
							v-if="tableSettings.operations.info"
							class="action-button info-button"
							size="mini"
							type="success"
							:icon="`icomoon ${tableSettings.operations.info.icon}`"
							@click="emitEvent(tableSettings.operations.info.eventName, scope.row)"
						/>

						<el-button
							v-if="tableSettings.operations.edit"
							size="mini"
							class="action-button"
							type="success"
							icon="icomoon icon-pencil"
							@click="editItem({ rowIdx: scope.$index, row: scope.row })"
						/>
						<el-button
							v-if="tableSettings.operations.delete"
							class="action-button"
							:loading="itemsSaving"
							size="mini"
							type="danger"
							icon="icomoon icon-cross"
							@click="handleDelete(scope.$index, scope.row)"
						/>
					</div>

					<div v-show="activeRowIdx == scope.$index">
						<el-button
							v-if="tableSettings.operations.edit"
							:loading="itemsSaving"
							size="mini"
							class="action-button"
							type="success"
							icon="icomoon icon-check"
							@click="saveItem"
						/>
						<el-button
							class="action-button"
							size="mini"
							type="danger"
							icon="icomoon icon-stop"
							@click="activeRowIdx = null"
						/>
					</div>
				</template>
			</el-table-column>

			<!-- :property="'status'" -->
		</el-table>

		<div v-if="!itemsLoading && !tableData.length" class="text-center section-block">
			{{ itemsName.mult }} не обнаружено
		</div>
	</div>
</template>

<script>
import 'element-ui/lib/theme-chalk/table.css';
import 'element-ui/lib/theme-chalk/table-column.css';

import { mapActions, mapState } from 'vuex';

import { getValues, getCellValue, getObjectVal, findItemBy } from '@/helpers';
import {
	deliveryTypesList,
	DELIVERY_TYPES,
	paymentsTypesList
} from '@/constants/global';

export default {
	components: {
		ElTable: () => import(/* webpackChunkName: "ElTable" */ 'element-ui/lib/table'),
		ElTableColumn: () =>
			import(/* webpackChunkName: "ElTableColumn"*/ 'element-ui/lib/table-column')
	},
	props: {
		itemsName: { type: Object, required: true },
		tableData: {
			type: Array,
			default: () => []
		},
		tableSettings: {
			type: Object,
			default: () => ({})
		},
		itemsLoading: Boolean,
		itemsSaving: Boolean,
		editByAlias: Boolean
		// clearable: Boolean,
		// query: String
	},

	data() {
		return {
			selectedIds: [],
			activeRowIdx: null
		};
	},
	computed: {
		...mapState({
			orderStatusesList: state => state.order_statuses.itemsList,
			orderStatusesLoading: state => state.order_statuses.isLoading
		}),

		getCellValue: () => getCellValue,
		getObjectVal: () => getObjectVal,

		orderInitialData: () => ({
			payment_type: null,
			comment: '',
			order_status_id: null,
			total_amount: 0,
			final_amount: 0
		})
	},

	methods: {
		...mapActions({
			fetch_order_statuses: 'order_statuses/fetch_order_statuses',
			set_order_statuses: 'order_statuses/set_order_statuses',
			update_order_status: 'orders/update_order_status',
			update_list_item: 'orders/update_list_item'
		}),
		/*toConsole(val) {
			console.log(val);
		},*/

		getDeliveryValue(item) {
			const delivery = findItemBy('id', item.delivery_type, deliveryTypesList);
			if (delivery) {
				// console.log(delivery.id, delivery.deliveryRegion)
				if (delivery.id === DELIVERY_TYPES.REGION && item.deliveryRegion) {
					return `<div>${item.deliveryRegion.title_ru}</div>
									<div><b>${item.deliveryRegion.price} грн</b></div>
					 			`;
				}
				return delivery.title_ru;
			}
			return '-';
		},
		getPaymentValue(item) {
			const payment = findItemBy('id', item.payment_type, paymentsTypesList);
			if (payment) {
				return payment.title_ru;
			}
			return '-';
		},

		handleStatusChange({ item, statusId /*, columnIndex*/ }) {
			const payload = {
				data: { status: statusId },
				itemId: item.id,
				notLoading: true
			};
			// console.log(payload)
			this.update_order_status(payload).then(() => {
				this.$emit('event', 'refreshOrdersList');

				/*let new_item = { ...this.tableData[columnIndex] };
				new_item.order_status_id = statusId;
				this.update_list_item({ idx:columnIndex, item:new_item })*/
			});
		},

		getWidth(operations) {
			const count = Object.keys(operations).length;
			let width = '113';
			if (count == 2) {
				width = '130';
			} else if (count >= 3) {
				width = '147';
			}
			return width;
		},

		getProperty(column) {
			return column.meta && column.meta.sortBy ? column.meta.sortBy : column.prop;
		},

		getOrderVal(order) {
			// it's a sorting direction
			if (!order) return null;

			if (order === 'descending') {
				return 'desc';
			} else if (order === 'ascending') {
				return 'asc';
			}
		},

		emitEvent(eventName, data) {
			this.$emit('event', eventName, data);
		},

		editItem(data) {
			// let newData = data;

			if (
				this.tableSettings.operations &&
				this.tableSettings.operations.editInTable
			) {
				this.activeRowIdx = data.rowIdx;
			} else {
				if (this.editByAlias) {
					data.id = data.row.alias;
				} else {
					data.id = data.row.id;
				}
				delete data.row;
				this.$emit('event', 'editItem', data);
			}
		},

		saveItem() {
			this.$emit('event', 'clearCollectedData');

			for (let ref of this.$refs.TableFormField) {
				ref.validateBlock();
			}
		},

		handleSelectionChange(val) {
			// console.log(val)
			const ids = getValues('id', val);

			this.selectedIds = ids;
		},

		handleDelete(index, row) {
			this.$confirm({
				title: 'Внимание',
				message: `Вы действительно хотите удалить выбранные заказы?`,
				confirmButtonText: 'Удалить',
				showCancelButton: true,
				cancelButtonText: 'Отменить',
				iconClass: 'icomoon icon-warning',
				type: 'warning'
			})
				.then(() => {
					this.$emit('event', 'deleteItem', { id: row.id });
				})
				.catch(() => {
					//
					/*this.$message({
						type: 'info',
						message: 'Delete canceled'
					});*/
				});
			// console.log(index, row);
		},

		handleSort({ prop, order }) {
			const data = {
				orderByColumn: order ? prop : null,
				orderByMethod: this.getOrderVal(order)
			};
			// console.log(data)
			this.$emit('event', 'setFilters', data);
		}
	},

	created() {
		this.fetch_order_statuses({ params: { max: -1 } });
	},

	beforeDestroy() {
		this.set_order_statuses([]);
	}
};
</script>
